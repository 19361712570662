.App {
  text-align: center;
}

.App-logo {
  width: 90%;
  max-width: 575px;
  pointer-events: none;
}

.App-header {
  margin: 20px auto 0 auto;
}

.App-link {
  color: #61dafb;
}

.App-description {
  margin: 30px auto 0 auto;
  margin-bottom: 15px;
  width: 50%;
  min-width: 400px;
  color: #1b468a;
  font-family: "Spartan", sans-serif;
}

.App-screen-img{
  width: 20%;
  min-width: 350px;
  pointer-events: none;
}

.App-signup-label {
  margin: 30px auto 0 auto;
  margin-bottom: 15px;
  width: 50%;
  min-width: 400px;
  color: #1b468a;
  font-family: "Spartan", sans-serif;
}

.App-language-select-label{
  margin: 30px auto 0 auto;
  margin-bottom: 15px;
  width: 50%;
  min-width: 400px;
  color: #1b468a;
  font-family: "Spartan", sans-serif;
}

.App-user-info-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;     /* Center items horizontally */
  width: 60%;
  max-width: 500px;
  min-width: 350px;
}

.App-phone-number-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0; /* Remove bottom margin */
}

.App-phone-prefix {
  padding: 0 10px;
  background-color: #edeff0;
  border-radius: 8px 0 0 8px;
  height: 50px; /* Match height to input */
  display: flex;
  align-items: center;
  font-family: "Spartan", sans-serif;
  font-size: 21px;
  font-weight: 500;
  box-sizing: border-box; /* Ensure padding is included in the height */
}

.App-phone-number-input {
  width: 100%;
  height: 50px; /* Ensure this matches the prefix height */
  padding-left: 10px; /* Adjust padding since +1 is separate */
  font-family: "Spartan", sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.84px;
  border: none;
  border-radius: 0 8px 8px 0;
  background: #edeff0;
  color: #1b468a;
  box-sizing: border-box; /* Ensure padding is included in the height */
}


.App-phone-warning {
  width: 100%;
  text-align: left; /* Align text to the left */
  margin-top: 0; /* No top margin */
  margin-bottom: 15px; /* Add space between the warning and next element */
  font-family: "Spartan", sans-serif;
  font-size: 14px;
  color: #1b468a; /* Optional: set warning color */
}

.App-email-input{
  width: 100%;  
  height: 50px;  
  margin-bottom: 15px;  
  padding-left: 24px; 
  font-family: "Spartan", sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.84px;
  border: none;
  border-radius: 8px;
  background: #edeff0;
  color: black;
  box-sizing: border-box;
}

.App-phone-number-submit-button {
  width: 100%; 
  max-width: 500px;            
  height: 50px;            /* Uniform height */
  margin-bottom: 15px;     /* Add space between each item */
  padding-left: 24px;      /* Left padding for inputs */
  font-family: "Spartan", sans-serif;
  font-size: 21px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.84px;
  border: none;
  border-radius: 8px;
  background: #edeff0;
  color: black;
}

.App-language-select-section{
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;     /* Center items horizontally */
  width: 60%;
  max-width: 550px;
  min-width: 400px;
}

.App-language-select-container {
  margin: 0 auto;
  display: grid; /* Use grid layout */
  grid-template-columns: repeat(3, 1fr); /* Create 3 equal-width columns */
  gap: 10px; /* Optional: spacing between items */
  width: 60%;
  max-width: 500px;
  min-width: 350px;
}

.App-language-checkbox{
  margin-right: 8px;
}

.App-language-checkbox-label {
  color: #1b468a;
  font-family: "Spartan", sans-serif;
  font-size: 18px; /* Optional: adjust font size */
  margin-bottom: 5px; /* Spacing between checkboxes */
  display: flex;
  align-items: center; /* Align text and checkbox in a single row */
}

.App-delivery-timezone-section {
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;     /* Center items horizontally */
  width: 60%;
  max-width: 550px;
  min-width: 400px;
}

.App-time-select-label{
  margin: 30px auto 0 auto;
  margin-bottom: 15px;
  width: 50%;
  min-width: 400px;
  color: #1b468a;
  font-family: "Spartan", sans-serif;
}

.App-delivery-timezone-selectors {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.App-delivery-time-label,
.App-timezone-label {
  display: flex;
  flex-direction: column;
  width: 48%; 
  font-family: "Spartan", sans-serif;
  font-size: 18px; 
  color: #1b468a;
}

select {
  margin-top: 10px;
  padding: 10px;
  font-family: "Spartan", sans-serif;
  font-size: 16px; 
  color: #1b468a;
}

.App-phone-number-submit-button {
  width: 90%; 
  max-width: 520px;
  margin-top: 20px;  
  background: #6b96c1;
  color: #fff;
  cursor: pointer;
  padding: 0;  /* No padding for button */
}

.App-phone-number-submit-button:hover {
  filter: brightness(80%);
}

